// Example locale file for English, give this to your locale team to translate

const LOCALE_JA = {
  // Set Filter
  selectAll: "(全選択)",
  selectAllSearchResults: "(すべての検索結果を選択)",
  addCurrentSelectionToFilter: "現在の選択をフィルターに追加",
  searchOoo: "検索...",
  blanks: "(ブランク)",
  noMatches: "一致なし",

// Number Filter & Text Filter
  filterOoo: "フィルター...",
  equals: "一致",
  notEqual: "不一致",
  blank: "空白",
  notBlank: "空白以外",
  empty: "未選択",

// Number Filter
  lessThan: "未満",
  greaterThan: "超過",
  lessThanOrEqual: "以下",
  greaterThanOrEqual: "以上",
  inRange: "範囲内",
  inRangeStart: "開始",
  inRangeEnd: "終了",

// Text Filter
  contains: "含む",
  notContains: "含まない",
  startsWith: "先頭一致",
  endsWith: "末尾一致",

// Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "前",
  after: "後",

// Filter Conditions
  andCondition: "かつ",
  orCondition: "または",

// Filter Buttons
  applyFilter: "適用",
  resetFilter: "リセット",
  clearFilter: "クリア",
  cancelFilter: "キャンセル",

// Filter Titles
  textFilter: "テキストフィルタ",
  numberFilter: "数字フィルタ",
  dateFilter: "データフィルタ",
  setFilter: "フィルタ設定",

// Group Column Filter
  groupFilterSelect: "フィールドを選択",

// Advanced Filter
  advancedFilterContains: "含む",
  advancedFilterNotContains: "含まない",
  advancedFilterTextEquals: "一致",
  advancedFilterTextNotEqual: "不一致",
  advancedFilterStartsWith: "で始まる",
  advancedFilterEndsWith: "で終わる",
  advancedFilterBlank: "空白",
  advancedFilterNotBlank: "空白以外",
  advancedFilterEquals: "#ERROR!",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "チェック",
  advancedFilterFalse: "未チェック",
  advancedFilterAnd: "かつ",
  advancedFilterOr: "または",
  advancedFilterApply: "適用",
  advancedFilterBuilder: "ビルダー",
  advancedFilterValidationMissingColumn: "列が欠落しています",
  advancedFilterValidationMissingOption: "オプションが欠落しています",
  advancedFilterValidationMissingValue: "値が欠落しています",
  advancedFilterValidationInvalidColumn: "列が見つかりません",
  advancedFilterValidationInvalidOption: "オプションが見つかりません",
  advancedFilterValidationMissingQuote: "値の終端の引用符が欠落しています",
  advancedFilterValidationNotANumber: "値が数値ではありません",
  advancedFilterValidationInvalidDate: "値が有効な日付ではありません",
  advancedFilterValidationMissingCondition: "条件が欠落しています",
  advancedFilterValidationJoinOperatorMismatch: "条件内の結合演算子は同じである必要があります",
  advancedFilterValidationInvalidJoinOperator: "結合演算子が見つかりません",
  advancedFilterValidationMissingEndBracket: "終端の括弧が欠落しています",
  advancedFilterValidationExtraEndBracket: "終端の括弧が多すぎます",
  advancedFilterValidationMessage: "式にエラーがあります。${variable} - ${variable}。",
  advancedFilterValidationMessageAtEnd: "式にエラーがあります。式の最後に${variable}。",
  advancedFilterBuilderTitle: "高度なフィルター",
  advancedFilterBuilderApply: "適用",
  advancedFilterBuilderCancel: "キャンセル",
  advancedFilterBuilderAddButtonTooltip: "フィルターまたはグループを追加",
  advancedFilterBuilderRemoveButtonTooltip: "削除",
  advancedFilterBuilderMoveUpButtonTooltip: "上へ移動",
  advancedFilterBuilderMoveDownButtonTooltip: "下へ移動",
  advancedFilterBuilderAddJoin: "グループを追加",
  advancedFilterBuilderAddCondition: "フィルターを追加",
  advancedFilterBuilderSelectColumn: "列を選択",
  advancedFilterBuilderSelectOption: "オプションを選択",
  advancedFilterBuilderEnterValue: "値を入力...",
  advancedFilterBuilderValidationAlreadyApplied: "現在のフィルターは既に適用されています。",
  advancedFilterBuilderValidationIncomplete: "すべての条件が完了していません。",
  advancedFilterBuilderValidationSelectColumn: "列を選択する必要があります。",
  advancedFilterBuilderValidationSelectOption: "オプションを選択する必要があります。",
  advancedFilterBuilderValidationEnterValue: "値を入力する必要があります。",

// Side Bar
  columns: "表示項目",
  filters: "フィルタ",

// columns tool panel
  pivotMode: "ピポットモード",
  groups: "行グループ",
  rowGroupColumnsEmptyMessage: "ドラッグして行グループにセット",
  values: "値",
  valueColumnsEmptyMessage: "ドラッグして合計にセット",
  pivots: "行ラベル",
  pivotColumnsEmptyMessage: "ドラッグして行ラベルにセット",

// Header of the Default Group Column
  group: "グループ",

// Row Drag
  rowDragRow: "行",
  rowDragRows: "行",

// Other
  loadingOoo: "読込中...",
  loadingError: "エラー",
  noRowsToShow: "表示データがありません",
  enabled: "有効",

// Menu
  pinColumn: "固定列",
  pinLeft: "左固定",
  pinRight: "右固定",
  noPin: "固定解除",
  valueAggregation: "合計値",
  noAggregation: "なし",
  autosizeThisColumn: "自動幅調整",
  autosizeAllColumns: "全自動幅調整",
  groupBy: "グループ化",
  ungroupBy: "グループ解除",
  ungroupAll: "すべてのグループ化を解除",
  addToValues: "${variable}を値に追加",
  removeFromValues: "${variable}を値から削除",
  addToLabels: "${variable}をラベルに追加",
  removeFromLabels: "${variable}をラベルから削除",
  resetColumns: "列リセット",
  expandAll: "すべて展開",
  collapseAll: "すべて折りたたむ",
  copy: "コピー",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "コピー(列名を含む)",
  copyWithGroupHeaders: "コピー（グループ列名を含む）",
  cut: "切り取り",
  paste: "貼り付け",
  ctrlV: "Ctrl+V",
  export: "エクスポート",
  csvExport: "CSVエクスポート",
  excelExport: "Excelエクスポート",
  columnFilter: "列フィルター",
  columnChooser: "列の選択",
  sortAscending: "昇順",
  sortDescending: "降順",
  sortUnSort: "解除",

// Enterprise Menu Aggregation and Status Bar
  sum: "合計",
  first: "最初",
  last: "最後",
  min: "最小",
  max: "最大",
  none: "なし",
  count: "個数",
  avg: "平均",
  filteredRows: "フィルター行数",
  selectedRows: "選択行数",
  totalRows: "全行数",
  totalAndFilteredRows: "行数",
  more: "さらに表示",
  to: "〜",
  of: "/",
  page: "ページ",
  pageLastRowUnknown: "？",
  nextPage: "次ページ",
  lastPage: "最終ページ",
  firstPage: "最初のページ",
  previousPage: "前のページ",
  pageSizeSelectorLabel: "ページ：",
  footerTotal: "合計",

// Pivoting
  pivotColumnGroupTotals: "合計",

// Enterprise Menu (Charts)
  pivotChartAndPivotMode: "ピポットグラフとモード",
  pivotChart: "ピポットグラフ",
  chartRange: "ピポット範囲",
  columnChart: "列",
  groupedColumn: "グループ列",
  stackedColumn: "積上げ行",
  normalizedColumn: "100% 積上げ",
  barChart: "棒グラフ",
  groupedBar: "グループ",
  stackedBar: "積上げ",
  normalizedBar: "100% 積上げ",
  pieChart: "円グラフ",
  pie: "円",
  donut: "ドーナッツ",
  line: "線グラフ",
  xyChart: "2軸チャート",
  scatter: "散布図",
  bubble: "バブル",
  areaChart: "面グラフ",
  area: "麺",
  stackedArea: "積上げ",
  normalizedArea: "100% 積上げ",
  histogramChart: "ヒストグラム",
  polarChart: "極座標",
  radarLine: "レーダーライン",
  radarArea: "レーダーエリア",
  nightingale: "ナイチンゲール",
  radialColumn: "放射状カラム",
  radialBar: "放射状バー",
  statisticalChart: "統計",
  boxPlot: "ボックスプロット",
  rangeBar: "レンジバー",
  rangeArea: "レンジエリア",
  hierarchicalChart: "階層",
  treemap: "ツリーマップ",
  sunburst: "サンバースト",
  specializedChart: "特殊",
  waterfall: "ウォーターフォール",
  heatmap: "ヒートマップ",
  combinationChart: "コンビネーション",
  columnLineCombo: "カラムとライン",
  AreaColumnCombo: "エリアとカラム",

// Charts
  pivotChartTitle: "ピポットチャート",
  rangeChartTitle: "範囲グラフ",
  settings: "設定",
  data: "データ",
  format: "形式",
  categories: "カテゴリ",
  defaultCategory: "(なし)",
  series: "順序",
  switchCategorySeries: "カテゴリー/シリーズの切り替え",
  categoryValues: "カテゴリー値",
  seriesLabels: "シリーズラベル",
  aggregate: "集計",
  xyValues: "XY値",
  paired: "2値モード",
  axis: "軸",
  xAxis: "水平軸",
  yAxis: "垂直軸",
  polarAxis: "極軸",
  radiusAxis: "半径軸",
  navigator: "ナビゲーター",
  zoom: "ズーム",
  animation: "アニメーション",
  crosshair: "クロスヘア",
  color: "色",
  thickness: "太さ",
  preferredLength: "推奨長さ",
  xType: "Xの種類",
  axisType: "軸タイプ",
  automatic: "自動",
  category: "カテゴリ",
  number: "数値",
  time: "時間",
  timeFormat: "時間形式",
  autoRotate: "自動回転",
  labelRotation: "回転",
  circle: "円",
  polygon: "多角形",
  square: "四角形",
  cross: "クロス",
  diamond: "ダイヤモンド",
  plus: "プラス",
  triangle: "三角形",
  heart: "ハート",
  orientation: "方向",
  fixed: "固定",
  parallel: "平行",
  perpendicular: "垂直",
  radiusAxisPosition: "位置",
  ticks: "目盛り",
  gridLines: "グリッド線",
  width: "幅",
  height: "高さ",
  length: "長さ",
  padding: "余白",
  spacing: "間隔",
  chartStyle: "チャートスタイル",
  title: "タイトル",
  chartTitles: "タイトル",
  chartTitle: "チャートタイトル",
  chartSubtitle: "サブタイトル",
  horizontalAxisTitle: "水平軸タイトル",
  verticalAxisTitle: "垂直軸タイトル",
  polarAxisTitle: "極軸タイトル",
  titlePlaceholder: "グラフタイトル - ダブルクリックで編集",
  background: "背景",
  font: "フォント",
  weight: "太さ",
  top: "上",
  right: "右",
  bottom: "下",
  left: "左",
  labels: "ラベル",
  calloutLabels: "コールアウトラベル",
  sectorLabels: "セクターラベル",
  positionRatio: "位置比率",
  size: "大きさ",
  shape: "形状",
  minSize: "最小サイズ",
  maxSize: "最大サイズ",
  legend: "凡例",
  position: "位置",
  markerSize: "マーカーの大きさ",
  markerStroke: "マーカーの太さ",
  markerPadding: "マーカーの余白",
  itemSpacing: "間隔",
  itemPaddingX: "余白(横)",
  itemPaddingY: "余白(縦)",
  layoutHorizontalSpacing: "横の間隔",
  layoutVerticalSpacing: "縦の間隔",
  strokeWidth: "線の太さ",
  offset: "オフセット",
  offsets: "オフセット",
  tooltips: "注釈",
  callout: "吹き出し",
  markers: "マーカー",
  shadow: "影",
  blur: "ブラー",
  xOffset: "X位置",
  yOffset: "Y位置",
  lineWidth: "幅",
  lineDash: "線の破線",
  lineDashOffset: "破線オフセット",
  scrollingZoom: "スクロール",
  scrollingStep: "スクロールステップ",
  selectingZoom: "選択",
  durationMillis: "期間 (ms)",
  crosshairLabel: "ラベル",
  crosshairSnap: "ノードにスナップ",
  normal: "標準",
  bold: "ボールド",
  italic: "イタリック",
  boldItalic: "ボールドイタリック",
  predefined: "定義済み",
  fillOpacity: "透明度",
  strokeColor: "線の色",
  strokeOpacity: "透明度(線)",
  miniChart: "ミニチャート",
  histogramBinCount: "区画数",
  connectorLine: "コネクター線",
  seriesItems: "シリーズアイテム",
  seriesItemType: "アイテムタイプ",
  seriesItemPositive: "ポジティブ",
  seriesItemNegative: "ネガティブ",
  seriesItemLabels: "アイテムラベル",
  columnGroup: "列グループ",
  barGroup: "棒フラグ",
  pieGroup: "円グラフ",
  lineGroup: "線グラフ",
  scatterGroup: "2軸グラフ",
  areaGroup: "面グラフ",
  polarGroup: "極",
  statisticalGroup: "統計",
  hierarchicalGroup: "階層",
  specializedGroup: "専門",
  combinationGroup: "コンビネーション",
  groupedColumnTooltip: "グループ",
  stackedColumnTooltip: "積上げ",
  normalizedColumnTooltip: "100% 積上げ",
  groupedBarTooltip: "グループ",
  stackedBarTooltip: "積上げ",
  normalizedBarTooltip: "100% 積上げ",
  pieTooltip: "円フラグ",
  donutTooltip: "ドーナツ",
  lineTooltip: "線グラフ",
  groupedAreaTooltip: "面グラフ",
  stackedAreaTooltip: "積上げグラフ",
  normalizedAreaTooltip: "100% 積上げ",
  scatterTooltip: "散布図",
  bubbleTooltip: "バブル",
  histogramTooltip: "ヒストグラム",
  radialColumnTooltip: "ラジアルカラム",
  radialBarTooltip: "ラジアルバー",
  radarLineTooltip: "レーダーライン",
  radarAreaTooltip: "レーダーエリア",
  nightingaleTooltip: "ナイチンゲール",
  rangeBarTooltip: "範囲バー",
  rangeAreaTooltip: "範囲エリア",
  boxPlotTooltip: "ボックスプロット",
  treemapTooltip: "ツリーマップ",
  sunburstTooltip: "サンバースト",
  waterfallTooltip: "ウォーターフォール",
  heatmapTooltip: "ヒートマップ",
  columnLineComboTooltip: "カラム＆ライン",
  areaColumnComboTooltip: "エリア＆カラム",
  customComboTooltip: "カスタムコンビネーション",
  innerRadius: "内側半径",
  startAngle: "開始角度",
  endAngle: "終了角度",
  reverseDirection: "逆方向",
  groupPadding: "グループパディング",
  seriesPadding: "シリーズパディング",
  tile: "タイル",
  whisker: "ウィスカー",
  cap: "キャップ",
  capLengthRatio: "長さ比率",
  labelPlacement: "配置",
  inside: "内側",
  outside: "外側",
  noDataToChart: "チャートに表示するデータがありません",
  pivotChartRequiresPivotMode: "ピポットチャートにはピポットモードが必要です",
  chartSettingsToolbarTooltip: "メニュー",
  chartLinkToolbarTooltip: "グリッドにリンク",
  chartUnlinkToolbarTooltip: "グリッドのリンク解除",
  chartDownloadToolbarTooltip: "グラフダウンロード",
  chartMenuToolbarTooltip: "メニュー",
  chartEdit: "チャートを編集",
  chartAdvancedSettings: "詳細設定",
  chartLink: "グリッドにリンク",
  chartUnlink: "グリッドからリンク解除",
  chartDownload: "チャートをダウンロード",
  histogramFrequency: "頻度",
  seriesChartType: "シリーズチャートタイプ",
  seriesType: "シリーズタイプ",
  secondaryAxis: "第二軸",
  seriesAdd: "シリーズを追加",
  categoryAdd: "カテゴリを追加",
  bar: "バー",
  column: "カラム",
  histogram: "ヒストグラム",
  advancedSettings: "詳細設定",
  direction: "方向",
  horizontal: "水平",
  vertical: "垂直",
  seriesGroupType: "グループタイプ",
  groupedSeriesGroupType: "グループ化",
  stackedSeriesGroupType: "積み上げ",
  normalizedSeriesGroupType: "100%積み上げ",
  legendEnabled: "有効",
  invalidColor: "色の値が無効です",
  groupedColumnFull: "グループ化カラム",
  stackedColumnFull: "積み上げカラム",
  normalizedColumnFull: "100%積み上げカラム",
  groupedBarFull: "グループ化バー",
  stackedBarFull: "積み上げバー",
  normalizedBarFull: "100%積み上げバー",
  stackedAreaFull: "積み上げエリア",
  normalizedAreaFull: "100%積み上げエリア",
  customCombo: "カスタムコンビネーション",

// ARIA
  ariaAdvancedFilterBuilderItem: "${variable}。 レベル ${variable}。 編集するにはENTERを押してください。",
  ariaAdvancedFilterBuilderItemValidation: "${variable}。 レベル ${variable}。 ${variable} 編集するにはENTERを押してください。",
  ariaAdvancedFilterBuilderList: "高度なフィルタービルダリスト",
  ariaAdvancedFilterBuilderFilterItem: "フィルター条件",
  ariaAdvancedFilterBuilderGroupItem: "フィルターグループ",
  ariaAdvancedFilterBuilderColumn: "列",
  ariaAdvancedFilterBuilderOption: "オプション",
  ariaAdvancedFilterBuilderValueP: "値",
  ariaAdvancedFilterBuilderJoinOperator: "結合演算子",
  ariaAdvancedFilterInput: "高度なフィルター入力",
  ariaChecked: "チェック",
  ariaColumn: "列",
  ariaColumnGroup: "列グループ",
  ariaColumnFiltered: "フィルターが適用された列",
  ariaColumnSelectAll: "選択されたセルを変更",
  ariaDateFilterInput: "日付入力",
  ariaDefaultListName: "リスト",
  ariaFilterColumnsInput: "列フィルタ",
  ariaFilterFromValue: "値からフィルタ",
  ariaFilterInput: "フィルタ入力",
  ariaFilterList: "フィルターリスト",
  ariaFilterToValue: "値へフィルタ",
  ariaFilterValue: "フィルター値",
  ariaFilterMenuOpen: "フィルターメニューを開く",
  ariaFilteringOperator: "フィルタ操作",
  ariaHidden: "非表示",
  ariaIndeterminate: "不確定",
  ariaInputEditor: "エディタ",
  ariaMenuColumn: "列メニューを開くにはALT+↓を押してください",
  ariaFilterColumn: "フィルターを開くにはCTRL+ENTERを押してください",
  ariaRowDeselect: "スペースを押して行の選択解除",
  ariaRowSelectAll: "スペースを押してすべての行を選択",
  ariaRowToggleSelection: "スペースを押して行の選択/非選択切り替え",
  ariaRowSelect: "スペースを押して行を選択",
  ariaRowSelectionDisabled: "この行の選択は無効です",
  ariaSearch: "検索",
  ariaSortableColumn: "並べ替えるにはENTERを押してください",
  ariaToggleVisibility: "スペースを押して表示/非表示切り替え",
  ariaToggleCellValue: "セルの値を切り替えるにはSPACEを押してください",
  ariaUnchecked: "非チェック",
  ariaVisible: "表示",
  ariaSearchFilterValues: "検索フィルタ値",
  ariaPageSizeSelectorLabel: "ページサイズ",
  ariaChartMenuClose: "チャート編集メニューを閉じる",
  ariaChartSelected: "選択済み",
  ariaSkeletonCellLoadingFailed: "行の読み込みに失敗しました",
  ariaSkeletonCellLoading: "行データを読み込み中",

// ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "行グループ",
  ariaValuesDropZonePanelLabel: "値",
  ariaPivotDropZonePanelLabel: "列ラベル",
  ariaDropZoneColumnComponentDescription: "削除するには DELETE を押してください",
  ariaDropZoneColumnValueItemDescription: "集計タイプを変更するには ENTER を押してください",
  ariaDropZoneColumnGroupItemDescription: "ソートするには ENTER を押してください",

// used for aggregate drop zone, format
  ariaDropZoneColumnComponentAggFuncSeparator: "の",
  ariaDropZoneColumnComponentSortAscending: "昇順",
  ariaDropZoneColumnComponentSortDescending: "降順",
  ariaLabelDialog: "ダイアログ",
  ariaLabelColumnMenu: "セルメニュー",
  ariaLabelColumnFilter: "列フィルター",
  ariaLabelCellEditor: "セル編集",
  ariaLabelSelectField: "フィールド選択",

// aria labels for rich select
  ariaLabelRichSelectField: "リッチセレクトフィールド",
  ariaLabelRichSelectToggleSelection: "選択を切り替えるにはスペースを押してください",
  ariaLabelRichSelectDeselectAllItems: "すべてのアイテムの選択を解除するにはDELETEを押してください",
  ariaLabelRichSelectDeleteSelection: "アイテムの選択を解除するにはDELETEを押してください",
  ariaLabelTooltip: "注釈",
  ariaLabelContextMenu: "右クリックメニュー",
  ariaLabelSubMenu: "サブメニュー",
  ariaLabelAggregationFunction: "集計関数",
  ariaLabelAdvancedFilterAutocomplete: "高度なフィルターオートコンプリート",
  ariaLabelAdvancedFilterBuilderAddField: "高度なフィルタービルダーにフィールドを追加",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "高度なフィルタービルダーの列選択フィールド",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "高度なフィルタービルダーのオプション選択フィールド",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "高度なフィルタービルダーの結合演算子選択フィールド",

// ARIA Labels for the Side Bar
  ariaColumnPanelList: "列リスト",
  ariaFilterPanelList: "フィルターリスト",

// Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "、",
  decimalSeparator: "。",

// Data types
  True: "真",
  False: "偽",
  invalidDate: "無効な日付",
  invalidNumber: "無効な数値",
  january: "1月",
  february: "2月",
  march: "3月",
  april: "4月",
  may: "5月",
  june: "6月",
  july: "7月",
  august: "8月",
  september: "9月",
  october: "10月",
  november: "11月",
  december: "12月",

// Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS 午前/午後",
}

export default LOCALE_JA
