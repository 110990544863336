import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import {MainDataContext} from "../../main";
import {Query, Rows} from "../../../manager/carto3";
import _ from "lodash";
import LOCALE_JA from "../../../resources/aggrid/locale.ja";
import UseColumnDefs from "./column";
import PropTypes from "prop-types";
import {Close as CloseIcon} from "@mui/icons-material";

const styles = {
    root: {
        width: '75%',
        height: '60%',
        background: 'white',
        padding: '1rem',
        boxShadow: '1px 1px 8px #000',
        borderRadius: '8px',
        display: "flex",
        flexDirection: 'column',
        position: "relative",
    }
}

const RootAssignView = (props) => {

    const { state } = useContext(MainDataContext)
    const [rowData, setRowData] = useState()
    const [salesList, setSalesList] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [editedData, setEditedData] = useState([])
    const [dataUpdating, setDataUpdating] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [assignedCount, setAssignedCount] = useState(0)

    const apiRef = useRef()

    const onGridReady = (param) => {
        apiRef.current = param.api
    }

    const getRows = useCallback(() => {
        setOpenErrorDialog(false)
        let wheres = ["TRUE"]
        if (state.selectedTabelogIds.length) {
            wheres.push(`tabelog.TABELOG_ID IN ('${state.selectedTabelogIds.join("','")}')`)
        }

        Rows(`
        SELECT 
            tabelog.*,
            target.SALES_ID,
            target.SALES_NAME
        FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
        LEFT JOIN ${process.env.REACT_APP_CARTO_TABLE_NEW_SALES_TARGET_VIEW} AS target
        ON tabelog.TABELOG_ID = target.TABELOG_ID
        WHERE ${wheres.join(' AND ')}
        `).then(rows => {
            let aCnt = 0
            const d = rows.map(row => {
                row['SALES'] = row.SALES_ID ? {...{ID: row.SALES_ID, NAME: row.SALES_NAME}} : null
                if (row.SALES_ID) {
                    aCnt++
                }
                return row
            })
            setAssignedCount(aCnt)
            setEditedData([])
            setRowData(d)
        })
    }, [state.selectedTabelogIds])

    const getSales = () => {
        Rows(`
        SELECT
            ID,
            NAME
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}
        ORDER BY 1
        `).then(setSalesList)
    }

    useEffect(() => {
        getRows()
        getSales()
    }, [])


    const salesCellEditorSelector = useCallback((params) => {
        return {
            component: "agRichSelectCellEditor",
            valueFormatter: (p) => {
                return p
            },
            params: {
                values: [null, ...salesList],
                cellRenderer: (p) => {
                    if (!p.value) {
                        return <p style={{color: "grey"}}>未選択</p>
                    }
                    return p.value.NAME
                },
            },
        }

    }, [salesList])

    const columnDefs = UseColumnDefs({salesCellEditorSelector})

    const onCellValueChanged = _.debounce(() => {
        let e = []
        let aCnt = 0
        apiRef.current.forEachNode(node => {
            if (node.data.SALES_ID || node.data.SALES) {　aCnt++　}
            if (node.data.SALES || node.data.SALES_ID) {
                if (node.data.SALES?.ID !== node.data.SALES_ID) {
                    e.push({...node.data})
                }
            }
        })
        setAssignedCount(aCnt)
        setEditedData(e)
    }, 100)

    const onRowSelected = (e) => {
        setSelectedRows(apiRef.current.getSelectedRows())
    }

    const bulkSelectSales = useCallback((uid) => {
        let sales = salesList.find(v => v.ID === uid)
        if (!sales) { return }

        const selectedTabelogIds = selectedRows.map(r => r.TABELOG_ID)
        apiRef.current.forEachNodeAfterFilter(node => {
            if (selectedTabelogIds.includes(node.data.TABELOG_ID)) {
                node.setDataValue("SALES", sales)
            }
        })

        apiRef.current.deselectAll()

    }, [salesList, selectedRows])

    const submitAssign = useCallback(() => {
        setOpenErrorDialog(false)
        let queries = []
        let values = []
        editedData.forEach((data) => {

            if (data.SALES) {
                queries.push(`(:${values.length + 1}, :${values.length + 2}, :${values.length + 3}, :${values.length + 4}, :${values.length + 5})`)
                values.push(data.TABELOG_ID)
                values.push(data.HANA_NAME)
                values.push(data.SALES?.ID)
                values.push(data.SALES?.NAME)
                values.push(state.user.ID)
            } else {
                queries.push(`(:${values.length + 1}, :${values.length + 2}, NULL, NULL, :${values.length + 3})`)
                values.push(data.TABELOG_ID)
                values.push(data.HANA_NAME)
                values.push(state.user.ID)
            }
        })
        if (queries.length) {
            setDataUpdating(true)

            Query(`
            INSERT INTO ${process.env.REACT_APP_CARTO_TABLE_NEW_SALES_TARGET} 
            (TABELOG_ID, HANA_NAME, SALES_ID, SALES_NAME, CREATED_SALES_ID)
            VALUES
            ${queries.join(',')}
            `, values).then(() => {
                getRows()
                setDataUpdating(false)
            }).catch(e => {
                setDataUpdating(false)
                setOpenErrorDialog(true)
            })
        }
    }, [editedData, state.user])

    const getContextMenuItems = useCallback(params => {

        if (selectedRows.length) {
            return [
                ...params.defaultItems,
                "separator",
                {
                    name: "選択一括登録",
                    subMenu: salesList.map(s => {
                        return {
                            name: s.NAME,
                            action: () => {
                                bulkSelectSales(s.ID)
                            }
                        }
                    })
                }
            ]
        }

        const appendMenus = [
            ...params.defaultItems,
            "separator",
            {
                name: "この行に担当を追加",
                subMenu: salesList.map(s => {
                    return {
                        name: s.NAME,
                        action: () => {
                            console.log(s)
                        }
                    }
                })
            }
        ]
        if (selectedRows.length) {
            appendMenus.push({
                name: "選択行に担当を追加",
                subMenu: salesList.map(s => {
                    return {
                        name: s.NAME,
                        action: () => {
                            console.log(s)
                        }
                    }
                })
            })
        }

        return appendMenus
    }, [salesList, selectedRows])

    const getDistinctFilterParams = useCallback((p) => {
        if ((rowData?.length ?? 0) === 0) {
            p.success([])
            return
        }
        let keys = {}
        rowData.forEach((row) => {
            console.log(row[p.colDef.field])
            keys[row[p.colDef.field]] = 1
        })
        p.success(Object.keys(keys).sort())
    }, [rowData])

    const getDistinctSalesFilterParams = useCallback((p) => {
        if ((rowData?.length ?? 0) === 0) {
            p.success([])
            return
        }
        let keys = {}
        rowData.forEach((row) => {
            let k = row[p.colDef.field].NAME
            keys[k] = 1
        })
        p.success(Object.keys(keys).sort())
    }, [rowData])

    return (
        <Box style={styles.root}>
            <Box style={{
                margin: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Typography variant="h6">担当者の指定</Typography>
                <Box style={{flexGrow: 1}} />
                <IconButton onClick={props.onClose} style={{marginLeft: "1rem"}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <AgGridReact
                localeText={LOCALE_JA}
                className="ag-theme-balham"
                rowData={rowData}
                rowSelection="multiple"
                defaultColDef={{
                    filter: true,
                    floatingFilter: true,
                }}
                columnDefs={columnDefs.map(coldef => {
                    if (coldef.filterParams === "distinct") {
                        return {
                            ...coldef,
                            filterParams: {
                                values: getDistinctFilterParams
                            }
                        }
                    }
                    if (coldef.filterParams === "distinctSales") {
                        return {
                            ...coldef,
                            filterParams: {
                                values: getDistinctSalesFilterParams
                            }
                        }
                    }
                    return coldef
                })}
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                onRowSelected={onRowSelected}
                suppressRowClickSelection={true}
                getContextMenuItems={getContextMenuItems}
            />
            <Box style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginTop: "8px",
            }}>
                {rowData?.length && <Box style={{fontSize: "12px", color: "#3e3e3e"}}>{assignedCount.toLocaleString()}/{rowData?.length.toLocaleString()} ({((assignedCount/rowData?.length) * 100).toFixed(1)}%)</Box>}
                <Box style={{flexGrow: 1}} />
                <Button disabled={!editedData.length} onClick={getRows} variant="contained" style={{width: "100px", backgroundColor: "#fa6565"}}>リセット</Button>
                <Button disabled={!editedData.length} onClick={submitAssign} variant="contained" style={{width: "100px", backgroundColor: "#64ca64"}}>登録</Button>
            </Box>
            {dataUpdating && (
                <Box style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#81818184",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress />
                </Box>
            )}
            <Dialog open={openErrorDialog}>
                <DialogTitle>登録に失敗しました</DialogTitle>
                <DialogContent>
                    登録に失敗しました<br />他の人が作業していないか確認して再度作業してください
                </DialogContent>
                <DialogActions>
                    <Button onClick={getRows} variant="contained">再ロード</Button>
                    <Button onClick={submitAssign}>再試行</Button>
                    <Button onClick={() => setOpenErrorDialog(false)}>キャンセル</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}

RootAssignView.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default RootAssignView
