import React, {useContext, useEffect} from "react";
import {Rows} from "../../../manager/carto3";
import dayjs from "dayjs";
import {MainDataContext} from "../../main";
import {Box, MenuItem, Select, Typography} from "@mui/material";

const styles = {
    root: {
        margin: "0 1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.8rem"
    },
}

const RootHeaderDateRangeView = (props) => {

    const [dateRange, setDateRange] = React.useState()
    const { state, setStartDate, setEndDate } = useContext(MainDataContext)

    useEffect(() => {
        Rows(`SELECT DISTINCT DATE FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3} ORDER BY 1`)
            .then(rows => {
                setDateRange(rows.map(r => dayjs(r.DATE)))
            })
            .catch(e => {
                console.log(e)
            })
    }, [])

    useEffect(() => {
        if ((dateRange?.length ?? 0) === 0) { return }
        let d = dateRange.map(d => d.format("YYYY-MM-DD"))
        if (!state.startDate) {
            setStartDate(d[0])
        }
        if (!state.endDate) {
            setEndDate(d.pop())
        }
    }, [dateRange, state.startDate, state.endDate])

    const onSelectEndDate = (e) => {
        setEndDate(e.target.value)
    }

    const onSelectStartDate = (e) => {
        setStartDate(e.target.value)
    }

    return (
        <Box style={styles.root}>
            <Typography style={{fontSize: "14px"}}>集計期間：</Typography>
            {dateRange && <>
            <Select size="small" value={state.startDate} onChange={onSelectStartDate}>
               {dateRange?.map(date => {
                   if (!state.endDate || date.diff(dayjs(state.endDate), "month") <= 0) {
                       return <MenuItem value={date.format("YYYY-MM-DD")} key={`range-start-${date.format()}`}>{date.format("YYYY年MM月")}</MenuItem>
                   }
                   return null
               })}
           </Select>
            <Typography>〜</Typography>
            <Select size="small" value={state.endDate} onChange={onSelectEndDate}>
                {dateRange?.map(date => {
                    if (!state.startDate || date.diff(dayjs(state.startDate), "month") >= 0) {
                        return <MenuItem value={date.format("YYYY-MM-DD")} key={`range-end-${date.format()}`}>{date.format("YYYY年MM月")}</MenuItem>
                    }
                })}
            </Select>
            </>}
        </Box>
    )

}

export default RootHeaderDateRangeView
