import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {vectorQuerySource, VectorTileLayer} from "@deck.gl/carto";
import PropTypes from "prop-types";
import {EVENT_KEY_UPDATE_GRID_STATE, DataType, LOCAL_STORAGE_KEY_FILTER_MODEL} from "../../../../data/main/state";
import {filterModelToWheres} from "../../../../manager/filter";
import {MainDataContext} from "../../../main";
import {One} from "../../../../manager/carto3";
import {MapDataContext} from "../index";

const UseRootMapTabelogPointLayer = (props) => {

    const { state } = useContext(MainDataContext)
    const { state: mapState } = useContext(MapDataContext)
    const [filterModel, setFilterModel] = useState(null);
    const [layer, setLayer] = useState(null)

    useEffect(() => {
        window.addEventListener(EVENT_KEY_UPDATE_GRID_STATE, () => {
            const fm = localStorage.getItem(LOCAL_STORAGE_KEY_FILTER_MODEL)
            if (!fm) {
                setFilterModel(null)
                return
            }
            if (!filterModel || JSON.stringify(filterModel) !== fm) {
                setFilterModel(JSON.parse(fm))
            }
        })
    }, [])

    const checkCount = async (wheres, values) => {
        let cnt = await One(`
        SELECT COUNT(1)
        FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
        WHERE ${wheres.join(' AND ')}
        `, values)
        return cnt > 0
    }

    const data = useMemo(async () => {
        let wheres = filterModelToWheres(state.filterModel)
        let values = []

        if (!mapState.showPointLayer || state.dataType !== DataType.TABELOG) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }
        //
        // let salesWheres = ["TRUE"]
        // if (state.startDate) {
        //     values.push(state.startDate)
        //     salesWheres.push(`DATE >= :${values.length}`)
        // }
        // if (state.endDate) {
        //     values.push(state.endDate)
        //     salesWheres.push(`DATE <= :${values.length}`)
        // }

        if (state.showSelectedOnly) {
            wheres.push(`TABELOG_ID IN ('${state.selectedTabelogIds.join("','")}')`)
        }

        if (!(await checkCount(wheres, values))) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        return vectorQuerySource({
            accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
            connectionName: 'snowflakeconn',
            apiBaseUrl: 'https://gcp-asia-northeast1.api.carto.com',
            sqlQuery: `
            SELECT tabelog.* FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
            WHERE ${wheres.join(' AND ')}
            `,
            queryParameters: values,
        });
    }, [
        state.filterModel,
        state.startDate,
        state.endDate,
        state.showSelectedOnly,
        state.selectedTabelogIds,
        state.dataType,
        mapState.showPointLayer,
    ]);

    const getFillColor = useCallback((p) => {
        if (state.selectedTabelogIds.includes(p.properties["TABELOG_ID"])) {
            return [0,0,255]
        }
        return [255,0,0]
    }, [state.selectedTabelogIds])

    useEffect(() => {
        props.onLoadStart && props.onLoadStart()
    }, [filterModel, state.startDate, state.endDate])

    useEffect(() => {
        data.then(d => {
            if (d) {
                setLayer(
                    new VectorTileLayer({
                        id: 'retail_stores',
                        data,
                        getFillColor,
                        getPointRadius: 2,
                        pointRadiusScale: 2,
                        pointRadiusUnits: "pixels",
                        getLineColor: [0,0,0,100],
                        getLineWidth: 0.1,
                        lineWidthScale: 1,
                        pickable: true,
                        onDataLoad: () => {
                        },
                        onViewportLoad: e => {
                            props.onLoadFinish && props.onLoadFinish()
                        },
                        onClick: props.onClick,
                        onHover: props.onHover,
                        updateTriggers: {
                            getFillColor: [state.selectedTabelogIds],
                        }
                    })
                )
            } else {
                setLayer(null)
            }
        })
    }, [data])

    return layer
}

UseRootMapTabelogPointLayer.propTypes = {
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadFinish: PropTypes.func,
}

export default UseRootMapTabelogPointLayer
