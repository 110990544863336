import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {Box, IconButton, Typography} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
import {MainDataContext} from "../../main";
import { ColumnDefs } from "../list/column"
import {filterModelToValueString} from "../../../manager/filter";
import PropTypes from "prop-types";
import {DataType} from "../../../data/main/state";

const styles = {
    root: {
        minHeight: "60px",
        overflowX: "auto",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        background: "#e5fbf3",
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'baseline',
        background: '#4a7546',
        padding: '4px 8px',
        borderRadius: '16px',
        boxShadow: '1px 1px 3px #eee'
    },
    filterItemTitle: {
        color: '#f1f1f1',
        fontWeight: 'bold',
        fontSize: '13px'
    },
    filterItemValues: {
        fontSize: "12px",
        color: "#ffffff"
    },
    filterItemDeleteButton: {

    },
    filterItemDeleteIcon: {
        fontSize: "13px",
        color: "#fff",
    },
    selectItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'baseline',
        background: '#4c3fac',
        padding: '4px 8px',
        borderRadius: '16px',
        boxShadow: '1px 1px 3px #eee'
    },
    selectItemValue: {
        fontSize: "12px",
        color: "#fff",
        marginBottom: "1px",
    },
    selectItemDeleteButton: {
    },
    selectItemDeleteIcon: {
        fontSize: "13px",
        color: "#fff",
    },
    assignText: {
        fontSize: "14px",
        marginLeft: "0.5rem"
    },
    filterText: {
        fontSize: "14px",
        marginLeft: "0.5rem"
    },
}

const RootFilterView = (props) => {

    const { state, setSelectedTabelogIds, setFilterModel, setSelectedH3List } = useContext(MainDataContext)

    const onDeleteItem = (field) => {
        let f = {...state.filterModel}
        delete f[field]
        setFilterModel(f)
    }

    const onDeleteSelect = () => {
        setSelectedTabelogIds([])
        setSelectedH3List([])
    }

    return (
        <Box style={styles.root}>
            {state.dataType === DataType.TABELOG && state.selectedTabelogIds.length > 0 && (
                <>
                <Typography style={styles.assignText}>アサイン選択：</Typography>
                <Box style={styles.selectItem}>
                    <Typography style={styles.selectItemValue}>{state.selectedTabelogIds.length.toLocaleString()}件選択中</Typography>
                    <IconButton style={styles.selectItemDeleteButton} onClick={onDeleteSelect}>
                        <CloseIcon style={styles.selectItemDeleteIcon} />
                    </IconButton>
                </Box>
                </>
            )}
            <Typography style={styles.filterText}>フィルター：</Typography>
            {state.dataType === DataType.TABELOG && state.filterModel && Object.entries(state.filterModel).map(([field, filter]) =>
                <FilterItem field={field} filter={filter} onDelete={() => onDeleteItem(field)} />
            )}
        </Box>
    )

}

export default RootFilterView

const FilterItem = ({field, filter, onDelete}) => {

    const name = useMemo(() => {
        let item = ColumnDefs.find(col => col.field === field)
        return item?.headerName
    }, [field])

    const value = useMemo(() => {
        return filterModelToValueString(filter)
    }, [field, filter])

    return name ? (
        <Box style={styles.filterItem}>
            <Typography style={styles.filterItemTitle}>{name}：</Typography>
            <Typography style={styles.filterItemValues}>{value}</Typography>
            <IconButton style={styles.filterItemDeleteButton} onClick={onDelete}>
                <CloseIcon style={styles.filterItemDeleteIcon} />
            </IconButton>
        </Box>
    ) : null
}

FilterItem.propTypes = {
    field: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
}
