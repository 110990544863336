import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {colorContinuous, h3QuerySource, H3TileLayer} from "@deck.gl/carto";
import PropTypes from "prop-types";
import {MainDataContext} from "../../../main";
import {EVENT_KEY_UPDATE_GRID_STATE, LOCAL_STORAGE_KEY_FILTER_MODEL} from "../../../../data/main/state";
import {filterModelToWheres} from "../../../../manager/filter";
import {First, One} from "../../../../manager/carto3";
import {DataFilterExtension} from "@deck.gl/extensions";
import {colorScale} from "../../../../util";
import {MapDataContext} from "../index";
import _ from "lodash";
import {ConditionType} from "../../../../data/map/state";
import {MAP_LOCAL_STORAGE_KEY} from "../../../../data/map/reducer";


const ColorProfiles = {
    BAPC_TY: [
        [null, -0.001, [255, 75, 68]],
        [0.001, 1, [239, 243, 255]],
        [1, 3.548, [189, 215, 231]],
        [3.548, 13.124, [107, 174, 214]],
        [13.124, null, [34, 113, 181]],
    ],
    NSR_TY: [
        [null, -1, [255, 75, 68]],
        [1, 2664, [239, 243, 255]],
        [2664, 5472, [189, 215, 231]],
        [5472, 12120, [107, 174, 214]],
        [12120, null, [34, 113, 181]],
    ],
    GP_TY: [
        [null, -0.000001, [255, 75, 68]],
        [0.000001, 1257.7656, [239, 243, 255]],
        [1257.7656, 2584, [189, 215, 231]],
        [2584, 6313.5792, [107, 174, 214]],
        [6313.58, null, [34, 113, 181]],
    ],
    POPULATION: [
        [null, 10, [49, 54, 150]],
        [10, 100, [69, 117, 180]],
        [100, 1000, [117, 172, 208]],
        [1000, 2000, [170, 217, 233]],
        [2000, 3000, [225, 243, 248]],
        [3000, 4000, [254, 224, 144]],
        [4000, 5000, [253, 174, 97]],
        [5000, 7500, [244, 109, 67]],
        [7500, 100000, [215, 48, 39]],
        [100000, null, [165, 0, 38]],
    ]
}

const UseRootMapSalesH3Layer = (props) => {

    const { state, setSelectedH3List } = useContext(MainDataContext)
    const { state: mapState } = useContext(MapDataContext)
    const lastFilterModel = useRef()
    const [tabelogH3s, setTabelogH3s] = useState()
    const [filterModel, setFilterModel] = useState()
    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [conditions, setConditions] = useState()

    useEffect(() => {
        if (!mapState.visibleConditionType) {
            setMin(null)
            setMax(null)
            return
        }

        let wheres = ["TRUE"]
        let values = []

        if (state.startDate) {
            wheres.push(`DATE >= :${values.length + 1}`)
            values.push(state.startDate)
        }
        if (state.endDate) {
            wheres.push(`DATE <= :${values.length + 1}`)
            values.push(state.endDate)
        }

        let minCol = `${mapState.visibleConditionType}_MIN`
        let maxCol = `${mapState.visibleConditionType}_MAX`
        if (mapState.visibleConditionType === ConditionType.POPULATION) {
            minCol = `${mapState.visibleConditionType}_MAX`
            maxCol = `${mapState.visibleConditionType}_MAX`
        }

        First(`
        SELECT 
            MIN(${minCol}) AS MIN, 
            MAX(${maxCol}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}
        WHERE ${wheres.join(' AND ')}
        `, values).then(row => {
//            console.log("[SalesH3]", "load min max", row)
            setMin(row.MIN)
            setMax(row.MAX)
        })
    }, [mapState.visibleConditionType, state.startDate, state.endDate])


    useEffect(() => {
        window.addEventListener(EVENT_KEY_UPDATE_GRID_STATE, ()=>{
            const fm = localStorage.getItem(LOCAL_STORAGE_KEY_FILTER_MODEL)
            if (fm !== lastFilterModel.current) {
                lastFilterModel.current = fm
                setFilterModel(JSON.parse(fm))
            }
        })
    }, [])

    useEffect(() => {
        props.onLoadStart && props.onLoadStart()
    }, [filterModel, state.startDate, state.endDate, mapState.conditions, mapState.visibleConditionType])

    const checkCount = async (wheres, values) => {
        let cnt = await One(`
            SELECT COUNT(1) FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW} AS base
            WHERE ${wheres.join(' AND ')}
        `, values)
        return cnt > 0
    }

    const data = useMemo( async () => {
//        console.log("[H3]", "updated", state.startDate, state.endDate, conditions, mapState.visibleConditionType)
        if (!mapState.visibleConditionType) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        let wheres = ["TRUE"]
        let values = []
        if (state.startDate) {
            wheres.push(`DATE >= :${values.length + 1}`)
            values.push(state.startDate)
        }
        if (state.endDate) {
            wheres.push(`DATE <= :${values.length + 1}`)
            values.push(state.endDate)
        }

        if (conditions) {
            Object.entries(conditions).forEach(([field, cond]) => {
                if (cond) {
                    let col = `${field}_SUM`
                    if (field === 'POPULATION') {
                        col = `${field}_MAX`
                    }
                    wheres.push(`${col} BETWEEN :${values.length + 1} AND :${values.length + 2} + 1`)
                    values.push(cond[0])
                    values.push(cond[1])
                }
            })
        }

        if (!(await checkCount(wheres, values))) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        return h3QuerySource({
            accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
            connectionName: 'snowflakeconn',
            apiBaseUrl: 'https://gcp-asia-northeast1.api.carto.com',
            sqlQuery: `
                SELECT 
                    H3, 
                    POPULATION_MAX AS POPULATION, 
                    CASE WHEN AGG_COUNT > 0 THEN BAPC_TY_SUM / AGG_COUNT ELSE 0 END AS BAPC_TY, 
                    CASE WHEN AGG_COUNT > 0 THEN NSR_TY_SUM / AGG_COUNT ELSE 0 END AS NSR_TY,
                    CASE WHEN AGG_COUNT > 0 THEN GP_TY_SUM / AGG_COUNT ELSE 0 END AS GP_TY
                FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW} AS base
                WHERE ${wheres.join(' AND ')}
            `,
            queryParameters: values,
            aggregationExp: `
            ARRAY_AGG(H3) AS h3s,
            AVG(POPULATION) AS POPULATION,
            AVG(BAPC_TY) AS BAPC_TY,
            AVG(NSR_TY) AS NSR_TY,
            AVG(GP_TY) AS GP_TY,
        `,
            aggregationResLevel: 6,
        })
    }, [filterModel, state.startDate, state.endDate, conditions, mapState.visibleConditionType])

    const updateConditions = _.debounce((cond) => {
        setConditions(cond)
    }, 500)

    useEffect(() => {
        updateConditions(mapState.conditions)
    }, [mapState.conditions])

    const getFilterValue = useCallback(d => {
        return d.properties[mapState.visibleConditionType] > 0 ? 1 : 0

    }, [mapState.visibleConditionType])

    const getLineColor = useCallback((p) => {
        if (state.selectedH3List && p.properties["H3S"].split(",").find(h => state.selectedH3List.includes(h))) {
            return [255,0,0, 204]
        }
        return [221, 178, 124, 204]
    }, [state.selectedH3List])

    const getFillColor = useCallback((p, v) => {

        let profile = ColorProfiles[mapState.visibleConditionType]
        if (!profile) {
            return [100,100,100,parseInt(mapState.visibleConditionOpacity * 255)]
        }
        let val = parseFloat(p.properties[mapState.visibleConditionType])
        let col = profile.find(([min, max, color]) => {
            if (!_.isNil(min) && min > val) return false
            if (!_.isNil(max) && max < val) return false
            return true
        })
        if (!col) { return [100, 100, 100, parseInt(mapState.visibleConditionOpacity * 255)]}
        return [...col[2], parseInt(mapState.visibleConditionOpacity * 255)]
    }, [min, max, mapState.visibleConditionType, conditions, mapState.visibleConditionOpacity])

    const getLineWidth = useCallback((p) => {
        if (state.selectedH3List && p.properties["H3S"].split(",").find(h => state.selectedH3List.includes(h))) {
            return 2
        }
        return 1
    }, [state.selectedH3List])

    return new H3TileLayer({
        id: 'h3-hexagon-layer',
        data,
        getFillColor,
        getLineColor,
        getLineWidth,
        getFilterValue,
        lineWidthUnits: 'pixels',
        stroked: true,
        extruded: false,
        pickable: true,
        onClick: props.onClick,
        filterRange: [1, 1],
        updateTriggers: {
            getFillColor: [min, max, mapState.visibleConditionType, conditions, mapState.visibleConditionOpacity],
            getLineColor: [state.selectedH3List],
            getLineWidth: [state.selectedH3List],
            getFilterValue: [mapState.visibleConditionType],
        },
        extensions: [new DataFilterExtension({filterSize: 1})],
        onDataLoad: e => {
            console.log("[Sales]", "layer data loaded")
        },
        onViewportLoad: e => {
            props.onLoadFinish && props.onLoadFinish()
        },
    })
}

UseRootMapSalesH3Layer.propTypes = {
    onClick: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadFinish: PropTypes.func,
}

export default UseRootMapSalesH3Layer
