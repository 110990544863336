import React, {useContext, useEffect, useRef, useState} from "react"
import {Backdrop, Box, IconButton} from "@mui/material"
import HeaderView from "./header"
import {DataType, ViewDirection, ViewMode} from "../../data/main/state"
import {MainDataContext} from "../main"
import MapView from "./map"
import ListView from "./list"
import RightView from "./right"
import FilterView from "./filter"
import {Close as CloseIcon, DragHandle as DragHandleIcon} from "@mui/icons-material";
import AssignView from "./assign";
import GraphView from "./graph"
import {useResizable} from "react-resizable-layout";
import ListSalesView from "./list_sales";

const styles = {
    root: {
        display: "flex",
        flexDirection: 'column',
        width: "100vw",
        height: "100vh",
        minWidth: "1024px",
        minHeight: "768px",
    },
    baseBox: {
        flexGlow: 1,
        display: "flex",
        width: "100%",
        height: "100%"
    },
    resizerBox: {
        background: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'ns-resize',
        height: "16px",
    },
    backdropCloseButtonBox: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        width: '60px',
        height: '60px',
        background: '#ffffff82',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
    },
    backDrop: {
        zIndex: 2000
    },
}

const RootView = () => {

    const { state, setGraphData, setIsDragging } = useContext(MainDataContext)
    const [openAssignWindow, setOpenAssignWindow] = useState(false)
    const containerRef = useRef()

    const { setPosition, position, separatorProps} = useResizable({
        axis: 'y',
        containerRef,
        min: 100,
        max: window.innerHeight - 300,
        onResizeStart: () => { setIsDragging(true) },
        onResizeEnd: () => { setIsDragging(false) }
    })

    useEffect(() => {
        setPosition("50%")
    }, [])

    return (
        <>
            <Box style={styles.root}>
                <HeaderView onAssign={() => setOpenAssignWindow(true)} />
                <FilterView />
                <Box style={{display: "flex", flexGrow: 1, flexDirection: "row"}}>
                    <Box style={{...styles.baseBox, flexDirection: state.viewDirection === ViewDirection.Vertical ? "column": "row",}}
                        ref={containerRef}>
                        {state.viewMode.includes(ViewMode.Map) && <MapView height={state.viewMode.length === 2 ? position: "100%"} />}
                        {state.viewMode.length === 2 && (
                            <Box style={styles.resizerBox} {...separatorProps}>
                                <DragHandleIcon fontSize="12px" />
                            </Box>
                        )}
                        {state.viewMode.includes(ViewMode.List) && state.dataType === DataType.TABELOG && <ListView />}
                        {state.viewMode.includes(ViewMode.List) && state.dataType === DataType.SALES && <ListSalesView />}
                    </Box>
                    {state.viewMode.includes(ViewMode.Map) && <RightView />}
                </Box>
            </Box>
            <Backdrop style={styles.backDrop} open={openAssignWindow}>
                <Box style={styles.backdropCloseButtonBox}>
                    <IconButton onClick={() => setOpenAssignWindow(false)}>
                        <CloseIcon size="large" />
                    </IconButton>
                </Box>
                {openAssignWindow && <AssignView onClose={() => setOpenAssignWindow(false)} />}
            </Backdrop>
            <Backdrop style={styles.backDrop} open={!!state.graphData}>
                <GraphView onClose={() => setGraphData(null)} />
            </Backdrop>
        </>
    )
}

export default RootView
