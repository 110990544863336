import reducer, {ActionType} from "./reducer"
import initialState from "./state"
import {useReducer} from "react";

const UseMainData = () => {

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
    })

    const setViewMode = (value) => {
        dispatch({type: ActionType.SetViewMode, value})
    }

    const setFilterModel = (value) => {
        dispatch({type: ActionType.SetFilterModel, value})
    }

    const setViewDirection = (value) => {
        dispatch({type: ActionType.SetViewDirection, value})
    }

    const setSeparatorRate = (value) => {
        dispatch({type: ActionType.SetSeparatorRate, value})
    }

    const setMapInit = (value) => {
        dispatch({type: ActionType.SetMapInit, value})
    }

    const setSelectedH3List = (value) => {
        dispatch({type: ActionType.SetSelectedH3List, value})
    }

    const setListH3List = (value) => {
        dispatch({type: ActionType.SetListH3List, value})
    }

    const setStartDate = (value) => {
        dispatch({type: ActionType.SetStartDate, value})
    }

    const setEndDate = (value) => {
        dispatch({type: ActionType.SetEndDate, value})
    }

    const setSelectedTabelogIds = (value) => {
        dispatch({type: ActionType.SetSelectedTabelogIds, value})
    }

    const setFilter = (value) => {
        dispatch({type: ActionType.SetFilter, value})
    }

    const appendSelectedTabelogId = (value) => {
        dispatch({type: ActionType.AppendSelectedTabelogId, value})
    }

    const dropSelectedTabelogId = (value) => {
        dispatch({type: ActionType.DropSelectedTabelogId, value})
    }

    const setShowSelectedOnly = (value) => {
        dispatch({type: ActionType.SetShowSelectedOnly, value})
    }

    const setMapViewBounds = (value) => {
        dispatch({type: ActionType.SetMapViewBounds, value})
    }

    const setGraphData = (value) => {
        dispatch({type: ActionType.SetGraphData, value})
    }

    const setIsDragging = (value) => {
        dispatch({type: ActionType.SetIsDragging, value})
    }

    const setUser = (value) => {
        dispatch({type: ActionType.SetUser, value})
    }

    const setFilterModelSales = (value) => {
        dispatch({type: ActionType.SetFilterModelSales, value})
    }

    const setDataType = (value) => {
        dispatch({type: ActionType.SetDataType, value})
    }

    return {
        state,
        setViewMode,
        setFilterModel,
        setViewDirection,
        setSeparatorRate,
        setMapInit,
        setSelectedH3List,
        setListH3List,
        setStartDate,
        setEndDate,
        setSelectedTabelogIds,
        setFilter,
        appendSelectedTabelogId,
        dropSelectedTabelogId,
        setShowSelectedOnly,
        setMapViewBounds,
        setGraphData,
        setIsDragging,
        setUser,
        setFilterModelSales,
        setDataType,
    }
}

export default UseMainData
