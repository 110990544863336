import {MAP_LOCAL_STORAGE_KEY} from "./reducer";
import {BaseLayers} from "../../manager/map";
import _ from "lodash";

export const ConditionType = {
    BAPC_TY: "BAPC_TY",
    NSR_TY: "NSR_TY",
    GP_TY: "GP_TY",
    POPULATION: "POPULATION",
}

export const ConditionName = {
    [ConditionType.BAPC_TY]: "原価",
    [ConditionType.NSR_TY]: "収益",
    [ConditionType.GP_TY]: "利益",
    [ConditionType.POPULATION]: "人口",
}

export const MapStyleItems = [
    {
        name: "黒地図",
        mapStyle: "mapbox://styles/mapbox/dark-v10",
    },
    {
        name: "白地図",
        mapStyle: "mapbox://styles/mapbox/light-v11",
    },
]


const MapDataState = {

    mapInit: {
        center: [130.52423159604, 33.290890138627],
        zoom: 16,
    },
    selectedConditionType: null,
    visibleConditionType: null,
    visibleConditionOpacity: 0.8,
    conditions: {},
    showPointLayer: true,
    mapStyle: _.first(MapStyleItems).mapStyle,
    ...(localStorage.getItem(MAP_LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(MAP_LOCAL_STORAGE_KEY)) : {}),
}

export default MapDataState
