
export const ColumnDefs = [
    {
        headerName: "No",
        valueGetter: e => (e.node?.rowIndex === null || e.node?.rowIndex === undefined) ? null: e.node.rowIndex + 1,
        width: 70,
        cellStyle: {textAlign: "right"},
        filter: false,
        pinned: true,
    },
    {
        headerName: "ディーラーコード",
        field: "DEALER_CODE",
        width: 120,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "BAPC",
        field: "BAPC",
        width: 80,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "NSR",
        field: "NSR",
        width: 80,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "GP",
        field: "GP",
        width: 80,
        filter: "agNumberColumnFilter",
    },
]
