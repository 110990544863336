export const LOCAL_STORAGE_KEY = "__main_state__"
export const LOCAL_STORAGE_KEY_FILTER_MODEL = "_filter_model"
export const LOCAL_STORAGE_KEY_COLUMN_STATE = "_column_state"
export const EVENT_KEY_UPDATE_GRID_STATE = "update-grid-storage"
export const LOCAL_STORAGE_KEY_COLUMN_DEFS = "_column_defs"

export const ViewMode = {
    Map: "map",
    List: "list",
}

export const ViewDirection = {
    Vertical: "vertical",
    Horizontal: "horizontal",
}

export const DataType = {
    TABELOG: "tabelog",
    SALES: "sales"
}

const MainState = {

    user: null,
    viewMode: [ViewMode.Map, ViewMode.List],
    filterModel: null,
    filterModelSales: null,
    viewDirection: ViewDirection.Vertical,
    separatorRate: 0.5,
    selectedH3List: [],
    listH3List: [],
    startDate: null,
    endDate: null,
    selectedTabelogIds: [],
    filter: null,
    showSelectedOnly: false,
    mapViewBounds: null,
    graphData: null,
    isDragging: null,
    ...(localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) : {}),
    dataType: DataType.TABELOG,

}

export default MainState
