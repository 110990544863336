import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList} from "@mui/material";
import {Check as CheckIcon, Layers as LayersIcon} from "@mui/icons-material";
import {useContext, useState} from "react";
import PropTypes from "prop-types";
import {MapDataContext} from "../index";
import {MapStyleItems} from "../../../../data/map/state";


const styles = {
    root: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        zIndex: '1000',
        width: '50px',
        height: '50px',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px',
        boxShadow: '1px 1px 3px #000',
    }
}

const RootMapBasemapController = (props) => {

    const [anchorEl, setAnchorEl] = useState()
    const { state, setMapStyle } = useContext(MapDataContext)

    return (
        <Box style={styles.root}>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                <LayersIcon />
            </IconButton>
            <Menu
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    {MapStyleItems.map(item => {
                        return <MenuItem>
                            <ListItemText onClick={() => setMapStyle(item.mapStyle)}>{item.name}</ListItemText>
                            <ListItemIcon>
                                {state.mapStyle === item.mapStyle && <CheckIcon />}
                            </ListItemIcon>
                        </MenuItem>
                    })}
                </MenuList>
            </Menu>
        </Box>
    )

}

RootMapBasemapController.propTypes = {
    onOpen: PropTypes.func,
    onSelect: PropTypes.func,
}

export default RootMapBasemapController
