import React, {useContext, useEffect, useState} from "react"
import {Box, Fab, Slider, Typography} from "@mui/material";
import RangeCondition from "./range"
import {ConditionType} from "../../../../data/map/state";
import {Close as CloseIcon, DragIndicator as DragIndicatorIcon} from "@mui/icons-material";
import {MapDataContext} from "../index";

const styles = {
    opacitySliderBox: {
        backgroundColor: '#FFFFFFD5',
        flexGrow: '1',
        padding: '8px 8px 8px 16px',
        display: 'flex',
        flexDirection: 'column',
        width: "200px",
        boxShadow: "1px 1px 3px #000",
    }
}

const RootMapConditionView = (props) => {

    const { state, setVisibleConditionOpacity } = useContext(MapDataContext)
    const [open, setOpen] = useState(false)
    const [opacity, setOpacity] = useState(state.visibleConditionOpacity * 100)

    useEffect(() => {
        let tm = setTimeout(() => {
            setVisibleConditionOpacity(opacity / 100)
        }, 1000)

        return () => {
            clearTimeout(tm)
        }
    }, [opacity])

    return (
        <Box style={{position: "absolute", left: "1rem", top: "1rem"}} >
            {!open && (<Fab size="small" onClick={() => setOpen(true)}>
                <DragIndicatorIcon />
            </Fab>)}
            {open && (
                    <Fab size="small" primary="secondary" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </Fab>
            )}
            {open && (<Box>
                <RangeCondition type={ConditionType.BAPC_TY} title="BAPC" />
                <RangeCondition type={ConditionType.NSR_TY} title="NSR" />
                <RangeCondition type={ConditionType.GP_TY} title="GP" />
                <RangeCondition type={ConditionType.POPULATION} title="人口" unit="人" />
                {state.visibleConditionType && (
                    <Box style={styles.opacitySliderBox}>
                        <Typography style={{fontSize: "12px", whiteSpace: "nowrap"}}>透明度</Typography>
                        <Box style={{display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'}}>
                            <Slider value={opacity} onChange={e => setOpacity(parseInt(e.target.value))} min={0} max={100} />
                            <Typography>{parseInt(opacity)}%</Typography>
                        </Box>
                    </Box>
                )}
            </Box>)}
        </Box>
    )

}

export default RootMapConditionView
