import React, {useContext} from "react"
import PropTypes from "prop-types"
import {Box, IconButton, Typography} from "@mui/material";
import { graphOptions } from "../right";

import { Bar } from "react-chartjs-2"
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from "chart.js"
import {MainDataContext} from "../../main";
import {Close as CloseIcon} from "@mui/icons-material";
import _ from "lodash";

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const styles = {
    root: {
        position: "absolute",
        background: 'white',
        padding: '1rem',
        width: '60%',
        height: '60%',
        borderRadius: '8px',
        boxShadow: '1px 1px 8px #000',
    },
    closeButton: {
        position: "absolute",
        top: "8px",
        right: "8px",
    },
    closeButtonIcon: {

    }
}

const RootGraphView = (props) => {

    const { state } = useContext(MainDataContext)

    return (
        <Box style={styles.root}>
            <IconButton onClick={props.onClose} style={styles.closeButton}><CloseIcon style={styles.closeButtonIcon} /></IconButton>
            <Box>
            {state.graphData && (
                <Bar
                    options={_.merge(graphOptions, {plugins: { title: { text: state.graphData.title }}})}
                    data={state.graphData.data}
                />
            )}
            </Box>
        </Box>
    )
}

RootGraphView.propTypes = {
    onClose: PropTypes.func,
}

export default RootGraphView
