import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import {Box, Button, Typography} from "@mui/material"
import {AgGridReact} from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.min.css"
import "ag-grid-community/styles/ag-theme-balham.min.css"
import "ag-grid-enterprise"
import LOCALE_JA from "../../../resources/aggrid/locale.ja";
import {One, Rows} from "../../../manager/carto3"
import { defaultColDef, ColumnDefs} from "./column";

import {
    booleanFilterParams,
    filterModelToWheres,
    UseDistinctFilterParams,
} from "../../../manager/filter";
import {MainDataContext} from "../../main";

import {
    LOCAL_STORAGE_KEY_COLUMN_DEFS,
    LOCAL_STORAGE_KEY_COLUMN_STATE,
    LOCAL_STORAGE_KEY_FILTER_MODEL,
} from "../../../data/main/state";
import _ from "lodash";

const LocalStorageKeyColumnDefs = `${LOCAL_STORAGE_KEY_COLUMN_DEFS}_tabelog`
const LocalStorageKeyColumnState = `${LOCAL_STORAGE_KEY_COLUMN_STATE}_tabelog`
const LocalStorageKeyFilterModel = `${LOCAL_STORAGE_KEY_FILTER_MODEL}_tabelog`

const styles = {
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: 'column',
    },
    controlBox: {
        height: '28px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        marginRight: '8px',
        margin: '0.5rem',
        gap: '8px',
    },
    listBox: {
        flexGrow: 1,
    },
}

const RootListView = React.memo(() => {

    const { state, setSelectedTabelogIds, setFilterModel, appendSelectedTabelogId, dropSelectedTabelogId } = useContext(MainDataContext)
    const apiRef = useRef()
    const gridRef = useRef()
    const columnApiRef = useRef()
    const oldSelectedH3ListRef = useRef([])
    const [rowCount, setRowCount] = useState()
    const [loadedRowCount, setLoadedRowCount] = useState()
    const [gridReady, setGridReady] = useState()

    const distinctFilterParams = UseDistinctFilterParams()

    useEffect(() => {
        setRowCount(null)
    }, [state.filterModel, setSelectedTabelogIds])

    const getRowCount = _.debounce((wheres) => {
        One(`
        SELECT COUNT(1) FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT}
        WHERE ${wheres.join(' AND ')}
        `).then(cnt => {
            console.log(cnt.toLocaleString())
            setRowCount(cnt)
        })

    }, 500)

    const dataSource = useMemo(() => {
        return {
            rowCount,
            getRows: (e) => {
                let orders = ['TABELOG_ID']
                let wheres = ["TRUE"]

                if (e.sortModel?.length) {
                    orders = e.sortModel.map(s => `${s.colId} ${s.sort}`)
                }

                if (e.filterModel) {
                    wheres = [...wheres, ...filterModelToWheres(e.filterModel)]
                }

                if (state.showSelectedOnly) {
                    wheres.push(`TABELOG_ID IN ('${state.selectedTabelogIds.join("','")}')`)
                }

                getRowCount(wheres)

                Rows(`
                SELECT ROW_NUMBER() OVER(ORDER BY ${orders.join(',')}) AS seq, *
                FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT}
                WHERE ${wheres.filter(v => !!v).join(" AND ")}
                ORDER BY ${orders.join(',')}
                LIMIT :1 OFFSET :2`
                    , [
                    e.endRow - e.startRow,
                    e.startRow
                ])
                    .then(rows => {
                        e.successCallback(rows)

                        setLoadedRowCount(e.startRow + rows.length)
                        setSelectedRows()
                    })
                    .catch(e => {
                        console.log(e)
                    })

                e.successCallback([])
            }
        }
    }, [state.showSelectedOnly])

    useEffect(() => {
        setSelectedRows()
    }, [state.selectedTabelogIds])

    const onGridReady = useCallback((params) => {
        apiRef.current = params.api
        columnApiRef.current = params.column

        if (localStorage.getItem(LocalStorageKeyColumnState)) {
            params.api.applyColumnState({state: JSON.parse(localStorage.getItem(LocalStorageKeyColumnState)), applyOrder: true})
        }
        if (localStorage.getItem(LocalStorageKeyFilterModel)) {
            params.api.setFilterModel(JSON.parse(localStorage.getItem(LocalStorageKeyFilterModel)))
        }
        setGridReady(true)
    }, [])

    const updateFilterModel = useCallback((fm) => {
        if (!gridReady) return
        setFilterModel(fm)
    }, [gridReady])

    const onFilterChanged = _.debounce(() => {
        updateFilterModel(apiRef.current.getFilterModel())
    }, 500)

    const onFilterModified = useCallback(() => {
        console.log("[List]", "on filter modified", apiRef.current.getFilterModel())
    })

    const onColumnStateChanged = _.debounce(() => {
        console.log("[List]", "change column state ", apiRef.current.getColumnState())
        const colDefs = apiRef.current.getGridOption("columnDefs").map(d => {
            if (d.filterParams?._type) {
                d.filterParams = d.filterParams._type
            }
            return d
        })
        localStorage.setItem(LocalStorageKeyColumnDefs, JSON.stringify(colDefs))
        localStorage.setItem(LocalStorageKeyColumnState, JSON.stringify(apiRef.current.getColumnState()))
    }, 1000)

    const setSelectedRows = useCallback(() => {
        apiRef.current?.forEachNode(node => {
            if(node?.data?.TABELOG_ID) {
                node.setSelected(state.selectedTabelogIds.includes(node.data.TABELOG_ID))
            }
        })
    }, [state.selectedTabelogIds])

    useEffect(() => {
        let tm = setTimeout(() => {
            updateSelectedTabelogIds()
        }, 500)

        return () => {
            clearTimeout(tm)
        }


    }, [state.selectedH3List])

    const updateSelectedTabelogIds = useCallback(() => {
        let addH3s = state.selectedH3List.filter(h3 => !oldSelectedH3ListRef.current.includes(h3))
        let removeH3s = oldSelectedH3ListRef.current.filter(h3 => !state.selectedH3List.includes(h3))

        Rows(`
        SELECT DISTINCT TABELOG_ID, 1 AS flag FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT}
        WHERE H3 IN ('${addH3s.join("','")}')
        UNION ALL
        SELECT DISTINCT TABELOG_ID, -1 AS flag FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT}
        WHERE H3 IN ('${removeH3s.join("','")}')
        `).then(rows => {
            let s = [...state.selectedTabelogIds]
            rows.forEach(row => {
                if (row.FLAG > 0 && !s.includes(row.TABELOG_ID)) {
                    s.push(row.TABELOG_ID)
                } else if(row.FLAG < 0 && s.includes(row.TABELOG_ID)) {
                    s.splice(s.indexOf(row.TABELOG_ID), 1)
                }
            })
            setSelectedTabelogIds([...s])
        })

        oldSelectedH3ListRef.current = [...state.selectedH3List]
    }, [state.selectedH3List, state.selectedTabelogIds])
    //
    // const onRowSelected = (e) => {
    //     if (!e.event) { return }
    //     console.log("on row selected", e)
    //     if (e.node.isSelected()) {
    //         dropSelectedTabelogId(e.data.TABELOG_ID)
    //     } else {
    //         appendSelectedTabelogId(e.data.TABELOG_ID)
    //     }
    // }

    const columnDefs = useMemo(() => {
        return ColumnDefs.map(d => {
            switch(d.filterParams) {
                case "boolean":
                    d.filterParams = booleanFilterParams
                    break
                case "distinct":
                    d.filterParams = distinctFilterParams
                    break
                default:
                    break
            }
            d.headerTooltip = d.headerTooltip ?? d.headerName

            return d
        })

    }, [ColumnDefs])

    const onResetColumnState = () => {
        apiRef.current.resetColumnState()
    }

    const onExportExcel = () => {
        apiRef.current.exportDataAsExcel()
    }

    const suppressRowClickSelection = e => {
        console.log(e)
        return true
    }

    const onCellClicked = useCallback(e => {
        console.log(e)
        if (e.column.colId === "RST_URL") {
            return
        }
        if (state.selectedTabelogIds.includes(e.data.TABELOG_ID)) {
            dropSelectedTabelogId(e.data.TABELOG_ID)
        } else {
            appendSelectedTabelogId(e.data.TABELOG_ID)
        }
    }, [state.selectedTabelogIds])


    return (
        <Box style={styles.root}>
            <Box style={styles.controlBox}>
                <Button size="small" variant="outlined" onClick={onResetColumnState}>列初期化</Button>
                <Button size="small" variant="outlined" onClick={onExportExcel}>Excelダウンロード</Button>
            </Box>
            <Box style={styles.listBox}>
                <AgGridReact
                    ref={gridRef}
                    localeText={LOCALE_JA}
                    onGridReady={onGridReady}
                    onCellClicked={onCellClicked}
                    onSortChanged={onColumnStateChanged}
                    onColumnResized={onColumnStateChanged}
                    onColumnVisible={onColumnStateChanged}
                    onColumnMoved={onColumnStateChanged}
                    onFilterChanged={onFilterChanged}
                    onFilterModified={onFilterModified}
                    className="ag-theme-balham"
                    rowModelType="infinite"
                    datasource={dataSource}
                    headerHeight={20}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "列選択",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel",
                                toolPanelParams: {
                                    suppressRowGroups: true,
                                    suppressValues: true,
                                    suppressPivots: true,
                                    suppressPivotMode: true,
                                    suppressColumnFilter: true,
                                    suppressColumnSelectAll: true,
                                    suppressColumnExpandAll: true,
                                },
                            },
                        ],
                    }}
                />
            </Box>
        </Box>
    )

})

export default RootListView
