import {numberBooleanColumnDef} from "../../../agGridUtils";
import PropTypes from "prop-types";

const UseColumnDefs = ({salesCellEditorSelector}) => {

    return [
        {
            width: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            filter: false,
            floatingFilter: false,
            lockPosition: "left",
            pinned: true,
        },
        {
            field: "SALES",
            headerName: "担当者名",
            lockPosition: "left",
            editable: true,
            cellEditorSelector: salesCellEditorSelector,
            cellRenderer: (p) => {
                return p.value?.NAME
            },
            cellStyle: (p) => {
                if (!p.value) { return null }
                if (p.value.UID !== p.data?.SALES_UID) {
                    return {backgroundColor: "rgba(161,161,246,0.4)"}
                }
                return null
            },
            filterParams: "distinctSales",
        },
        {
            field: "BEFORE_SALES_UID",
            headerName: "前回担当者ID",
            hide: true,
            filterParams: "distinct",
        },
        {
            field: "BEFORE_SALES_NAME",
            headerName: "前回担当者名",
            lockPosition: "left",
            filterParams: "distinct",
        },
        {
            field: "HANA_NAME",
            headerName: "HANA名",
            lockPosition: "left",
            filter: "agTextColumnFilter",
        },
        {
            field: "SAKAYA_NAME",
            headerName: "酒屋名",
            filter: "agTextColumnFilter",
        },
        {
            field: "SAKAYA_DEALER_NAME",
            headerName: "酒屋販売店名",
            filter: "agTextColumnFilter",
        },
        {
            field: "HANA_DEALER_CODE",
            headerName: "HANA販売店コード",
            filter: "agTextColumnFilter",
        },
        {
            field: "SAKAYA_CODE",
            headerName: "酒屋コード",
            filter: "agTextColumnFilter",
        },
        {
            field: "PREFECTURE",
            headerName: "都道府県",
            filterParams: "distinct",
        },
        {
            field: "CITY",
            headerName: "市区町村",
            filterParams: "distinct",
        },
    ]
}

UseColumnDefs.propTypes = {
    salesCellEditorSelector: PropTypes.any,
}

export default UseColumnDefs
