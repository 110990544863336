import React, {useCallback, useContext} from "react"
import UseMainData from "../data/main";
import RootView from "./root";
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl";
import {ErrorBoundary} from "react-error-boundary";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import LoginView from "./login";

export const MainDataContext = React.createContext()

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_API_KEY

const ErrorContent = React.createContext()

const ErrorDialog = ({error, resetErrorBoundary}) => {
    const { isOpen, setIsOpen} = useContext(ErrorContent)

    const handleClose = () => {
        setIsOpen(false)
        resetErrorBoundary()
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTitle>エラーが発生しました</DialogTitle>
            <DialogContent>
                申し訳ありません。予期しないエラーが発生しました。
                {error.message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
        </Dialog>
    )
}

const ErrorDialogBoundary = ({children}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleError = useCallback((error, info) => {
        setIsOpen(true)
    }, [])

    return (
        <ErrorContent.Provider value={{isOpen, setIsOpen}}>
            <ErrorBoundary
                FallbackComponent={ErrorDialog}
                onError={handleError}
            >
                {children}
            </ErrorBoundary>
        </ErrorContent.Provider>
    )
}

const MainView = () => {

    const useMainData = UseMainData()

    const { state } = useMainData

    return (
        <MainDataContext.Provider value={useMainData}>
            <ErrorDialogBoundary>
                {state.user && <RootView />}
                {!state.user && <LoginView />}
            </ErrorDialogBoundary>
        </MainDataContext.Provider>
    )
}

export default MainView
